import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import ShareSocial from '../../components/share-social'
import styles from './index.module.css'
import { Grid, Link, Typography, Button } from '@material-ui/core'

class Video extends React.Component {
  render() {
    const video = get(this.props, 'data.contentfulVideo')
    const categorie = get(this.props, 'data.contentfulVideo.categorie')
    const siteTitle = `Movimento Rivoluzione Fiscale - ${get(
      this,
      'props.data.site.siteMetadata.title'
    )}`

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${video.titolo} | ${siteTitle}`}>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.titleContainer}>
                {video.categorie.map((categoria) => (
                  <Typography variant={'h3'} className={styles.labelCategorie}>
                    {categoria.label}
                  </Typography>
                ))}
              </Grid>
              <Grid item lg={12}>
                <div
                  className={styles.embed}
                  dangerouslySetInnerHTML={{ __html: video.embed.embed }}
                />
                <div className={styles.content}>
                  <Typography variant={'h3'}>{video.titolo}</Typography>
                  <Typography variant={'subtitle1'} className={styles.author}>
                    {video.autore &&
                      `${video.autore.nome} ${video.autore.cognome}, ${video.dataPubblicazione}`}
                  </Typography>
                  <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                      __html: video.contenuto.childMarkdownRemark.html,
                    }}
                  />
                  <ShareSocial
                    link={this.props.location.href}
                    title={siteTitle}
                  />
                  <div className={styles.buttonsContainer}>
                    {video.categorie.map((categoria) => (
                      <Button
                        className={styles.buttonCategoria}
                        onClick={() => {
                          window.location.href = `/${categoria.slug}/1/`
                        }}
                      >
                        {categoria.label}: Tutti gli articoli
                      </Button>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Video

export const pageQuery = graphql`
  query VideoBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulVideo(slug: { eq: $slug }) {
      id
      titolo
      contenuto {
        childMarkdownRemark {
          html
        }
      }
      categorie {
        id
        label
        slug
      }
      autore {
        cognome
        nome
        descrizione
      }
      dataPubblicazione(formatString: "MMMM Do, YYYY")
      embed {
        embed
      }
    }
  }
`
